/* Header Toolbar */
.fc-toolbar-title {
  color: #000000;
  font-size: 16px !important;
  font-weight: 500;
  font-family: 'Montserrat';
}

.fc-toolbar-chunk button {
  background-color: #2653a3;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  font-family: 'Montserrat';
}

.fc-toolbar-chunk button:hover {
  background-color: #2653a3;
}
.fc .fc-daygrid-day-top {
  justify-content: center;
}

.fc-day-today {
}

/* Default Primary Button */
.fc .fc-button-primary {
  background-color: #f9f9f9;
  color: #000000;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  font-family: 'Montserrat';
}

/* On Hover */
.fc .fc-button-primary:hover {
  background-color: #d4dded4d;
  color: #2653a3 !important;
}

/* Active State */
.fc .fc-button-primary.fc-button-active {
  background-color: #d4dded4d !important;
  color: #2653a3 !important;
  border: '1px solid #2653A3' !important;
}

/* Events */
.fc-event {
  background-color: #2653a3 !important;
  border: none;
  color: white;
  font-size: 14px;
  padding: 0.1px;
  border-radius: 4px;
  font-family: 'Montserrat';
}

.fc-event:hover {
  background-color: #2653a3 !important;
}

/* Selected Date */
/* .fc-highlight {
    background-color: #2653A3 !important; 
  
  } */

/* Today Highlight */
.fc-day-today {
  background-color: #1976d21f !important;
  border: 1px solid #1976d21f !important;
}

/* List View Events */
.fc-list-item {
  border-bottom: 1px solid #ddd;
}

.fc-list-item:hover {
  background-color: #f9f9f9;
}

.fc .fc-col-header-cell-cushion {
  color: #000000;
  font-size: 16px !important;
  font-weight: 500;
  font-family: 'Montserrat';
}

.fc .fc-daygrid-day-number {
  font-family: 'Montserrat' !important;
  font-size: 12px !important;
}

@media (max-width: 768px) {
  .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
    min-height: 0em;
  }
}

@media (max-width: 768px) {
  .fc-toolbar-title {
    font-size: 13px !important;
  }
}
@media (max-width: 768px) {
  .fc .fc-button-primary.fc-button-active {
    font-size: 13px !important;
  }
}

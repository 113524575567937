.react-multiple-carousel__arrow--right {
  right: calc(0% + 1px) !important;
}
.react-multiple-carousel__arrow--left {
  left: calc(0% + 1px) !important;
}

.react-multiple-carousel__arrow {
  background-color: black;
  min-width: 5px !important;
  min-height: 35px !important;
  border: 2px solid #b9b9b9;
}

.react-multiple-carousel__arrow--left::before {
  content: '\e824' !important;
  color: '#000000' !important;
}
.react-multiple-carousel__arrow--right::before {
  content: '\e825' !important;
  color: '#000000' !important;
}

/* .image-uploader {
  display: flex;
  flex-direction: column;

}

.images-container {
  display: flex;
  flex-direction: row-reverse; 
  gap: 10px;
  flex-wrap: wrap;
  margin-left: 20px;
}

.image-wrapper {
  position: relative;
  display: inline-block;
}

.delete-icon-container {
    position: absolute;
    left: 5;
    border-radius: 47%;
    top: 3px;
    right: 4px;
    width: 20px;
    height: 20px;
  }
.delete-icon {
  color: red;
  text-align: center;
  border-radius: 50%;
  background-color: #c2bfbf;
  width: 20px;
  cursor: pointer;

}
.upload-label {
  padding: 30px;
  border: 2px dotted #3C5AA0;
  transition: border-color 0.3s;
  height: fit-content;
  margin-bottom: 10px;
  border-radius: 5px;
}
.upload-label-container {
  flex: 1;
}
.upload-label:hover {
  border-color: #aaa;
}

.upload-icon {
}

.error-message {
  color: red;
  margin: 10px 0;
  position: absolute;
  top: 30px;
}

.image-wrapper img {
  max-width: 100%;
  max-height: 100%;
  padding: 3px;
}
.upload-label:hover {
  border-color: blue;
}

.image-wrapper:hover .delete-icon {
  opacity: 1; 
}

.remove-all-button {
  cursor: pointer;
  color: white;
  background-color: rgb(249, 138, 53);
  height: 40px;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  transition: background-color 0.3s, color 0.3s;
}

.remove-all-button:hover {
  color: darkred;
}

.replace-button{
  background-color: #7b38d8;
  border-radius:5px ;
  padding: 5px;
  width:100px;
  margin-bottom: 10px !important;
  color: #ffffff;
  text-align: center;
} */

/* Add this CSS to your stylesheet */

/* Adjust the above styles as needed for your specific design */

.image-uploader {
  display: flex;
  padding-left: 30px;
  margin-bottom: 10px;
}
.dotted-box {
  border: 2px dotted #3c5aa0;
  padding: 14px;
  width: fit-content;
  cursor: pointer;
}
.images-container {
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
  flex-wrap: wrap;
  margin-left: 20px;
}
.image-wrapper {
  position: relative;
}
.delete-icon {
  position: absolute;
  border-radius: 50%;
  padding: 1px;
  background-color: #3c5aa0;
}

.main-slide .thumb {
  display: none;
}
.main-slide {
  width: 'auto';
}
.main-slide1 .thumb {
  display: none;
}

.facebook {
  color: #464646;
}
.facebook:hover {
  color: blue;
}
.youtube {
  color: #464646;
}
.youtube:hover {
  color: red;
}
.twitter {
  color: #464646;
}
.twitter:hover {
  color: rgb(47, 176, 246);
}
.linkdin {
  color: #464646;
}
.linkdin:hover {
  color: rgb(57, 107, 134);
}
.instagram {
  color: #464646;
}
.instagram:hover {
  color: rgb(228, 64, 95);
}

.react-multiple-carousel__arrow {
  background-color: 'red';
}
